<template>
  <v-app>
    <!--
    <app-header></app-header>
    -->

    <v-main class="primary">
      <router-view/>
    </v-main>

    <v-footer color="primary">
      <div class="text-center" style="width: 100%">
        <v-btn target="new" href="https://www.facebook.com/lovemybunskitchen" icon color="white">
          <v-icon>fab fa-facebook</v-icon>
        </v-btn>
        <v-btn target="new" href="https://www.instagram.com/lovemybunskitchen/?hl=en" icon color="white">
          <v-icon>fab fa-instagram</v-icon>
        </v-btn>
        <v-btn href="mailto:desirayf@hotmail.com" icon color="white">
          <v-icon>fas fa-envelope</v-icon>
        </v-btn>
        <v-btn href="tel:5194013929" icon color="white">
          <v-icon>fas fa-mobile-alt</v-icon>
        </v-btn>
      </div>
    </v-footer>

  </v-app>
</template>

<script>
//import AppHeader from '@/components/header/AppHeader'

export default {
  name: 'App',

  components: {
    //AppHeader
  }
};
</script>
